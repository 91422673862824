import BaseText from 'app/components/common/BaseText'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import {
  convertColumnTable,
  ExpandedTableValue,
} from 'app/components/tables/BaseTableReport/hook'
import { useMemo, useState } from 'react'
import {
  DefineRewardType,
  IActivityEvent,
  IRewardActivity,
  RewardTypeEnum,
} from '../../../CreateEvent/type'
import { ChildRow } from './layouts/ChildRow'
import { isEqual } from 'lodash'
import { ChildRowTeam } from './layouts'
import { IEvent } from 'app/api/event/model'

export const RewardLayout = ({
  activity,
}: {
  activity?: IActivityEvent
  event?: IEvent
}) => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<number[]>([])

  const handleExpand = (expanded: boolean, newKey?: number) => {
    if (!newKey) return
    if (expanded) {
      setExpandedRowKeys([...expandedRowKeys, newKey])
    } else {
      setExpandedRowKeys(expandedRowKeys.filter(key => key !== newKey))
    }
  }

  const data = useMemo(() => {
    return activity?.rewards?.map((item, index) => {
      return {
        ...item,
        key: index + 1,
      }
    })
  }, [activity?.rewards])

  console.log({ activity })

  return (
    <BaseTableManagement
      dataSource={data}
      columns={[
        convertColumnTable<IRewardActivity & { key: number }>({
          key: 'key',
          title: 'STT',
          classNameWidthColumnOverwrite: 'number-column',
          render: (index: number) => {
            return (
              <BaseText children={index} fontWeight="medium" opacity="0.7" />
            )
          },
        }),
        convertColumnTable<IRewardActivity>({
          key: 'name',
          title: 'Tên giải',
          classNameWidthColumnOverwrite: 'big-column',
          render: (_, record) => {
            const isExpanded = expandedRowKeys.includes(record.key ?? 0)
            const _handleExpand = () => {
              handleExpand(!isExpanded, record?.key)
            }

            return (
              <ExpandedTableValue
                isExpanded={isExpanded}
                handleExpand={_handleExpand}
                value={record?.name}
                isHaveAreaWhenNotHaveActionExpanded
              />
            )
          },
        }),
        convertColumnTable<IRewardActivity>({
          key: 'description',
          title: 'Mô tả',
          render: (text?: string) => {
            return (
              <BaseText children={text} fontWeight="medium" opacity="0.7" />
            )
          },
        }),
        convertColumnTable<IRewardActivity>({
          key: 'gift',
          title: 'Phần quà',
          render: (text?: string) => {
            return (
              <BaseText children={text} fontWeight="medium" opacity="0.7" />
            )
          },
        }),
        convertColumnTable<IRewardActivity>({
          key: 'countPerReward',
          title: 'Số lượng giải',
          render: (text?: number) => {
            return (
              <BaseText children={text} fontWeight="medium" opacity="0.7" />
            )
          },
        }),
        convertColumnTable<IRewardActivity>({
          key: 'type',
          title: 'Đối tượng nhận giải',
          render: (type: RewardTypeEnum) => {
            return (
              <BaseText
                children={DefineRewardType[type]}
                fontWeight="medium"
                colorText="statesGreenColor"
              />
            )
          },
        }),
      ]}
      scroll={{ x: true }}
      pagination={{
        pageSize: 5,
      }}
      rowClassName="row-overwrite-style"
      expandable={{
        expandedRowKeys,
        onExpand: handleExpand,
        expandIcon: () => null,
        showExpandColumn: false,
        expandedRowRender: (reward: IRewardActivity) =>
          isEqual(reward?.type, RewardTypeEnum.GROUP) ? (
            <ChildRowTeam
              reward={reward}
              actionId={activity?.id}
              teams={activity?.teams ?? []}
            />
          ) : (
            <ChildRow reward={reward} actionId={activity?.id} />
          ),
      }}
    />
  )
}
