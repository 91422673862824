/* eslint-disable @typescript-eslint/no-explicit-any */
import { EditOutlined } from '@ant-design/icons'
import { IPayloadAddTumByActionEvent } from 'app/api/event/model'
import { BaseButton } from 'app/components/common/BaseButton'
import { BaseCol } from 'app/components/common/BaseCol'
import { BaseRow } from 'app/components/common/BaseRow'
import { BaseSpace } from 'app/components/common/BaseSpace'
import BaseText from 'app/components/common/BaseText'
import { BaseForm } from 'app/components/common/forms/BaseForm'
import { BaseInput } from 'app/components/common/inputs/BaseInput'
import { InputNumber } from 'app/components/common/inputs/InputNumber'
import {
  BaseModalComponent,
  IRefModal,
} from 'app/components/common/ModalComponent'
import { BaseDatePicker } from 'app/components/common/pickers/BaseDatePicker'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { useUpdateTumInActionEvent } from 'app/react-query/hook/event'
import dayjs from 'dayjs'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import { ModalSelectTum } from './ModalSelectTum'
import {
  IActivityEvent,
  IColumnActivity,
  IDataTumActivity,
  TypeColumn,
} from './type'
import { notificationController } from 'app/controllers/notification-controller'

export const UpdateTumInActionEvent = ({
  actionEvent,
  teamId,
}: {
  actionEvent?: IActivityEvent
  teamId?: number
}) => {
  const modalRef = useRef<IRefModal>(null)
  const [tums, setTums] = useState<IDataTumActivity[]>([])

  const { mutateAsync, isLoading } = useUpdateTumInActionEvent()

  const handleSubmit = async () => {
    const payload = {
      activity_id: actionEvent?.id,
      tums:
        tums?.map(member => ({
          id: member?.id,
          is_checked_in: member?.checkedIn,
          list_key_map_check_in: actionEvent?.locations?.map(
            lo => lo?.key_map_check_in,
          ),
          columns: actionEvent?.columns?.map(col => {
            const key = col?.keyOfMainData ?? col?.name ?? ''
            const valueExist = member?.[key]
            return {
              name: key,
              value: valueExist,
            }
          }),
        })) ?? [],
      teamId,
    } as IPayloadAddTumByActionEvent

    console.log({ payload })

    await mutateAsync?.(payload)

    setTimeout(() => {
      modalRef?.current?.hide?.()
      notificationController.success({ message: 'Cập nhật thành công' })
    }, 1000)
  }

  useEffect(() => {
    if (teamId) {
      const teamSelect = actionEvent?.teams?.find(team => team?.id === teamId)
      setTums(teamSelect?.tums ?? [])
    } else {
      setTums(actionEvent?.data ?? [])
    }
  }, [actionEvent, teamId])

  const columns = useMemo(() => {
    if (!actionEvent?.columns?.length) {
      const res: IColumnActivity[] = [
        {
          name: 'Họ và tên',
          keyOfMainData: 'fullName',
          type: TypeColumn.STRING,
        },
        {
          name: 'Mã số',
          keyOfMainData: 'mql',
          type: TypeColumn.DATE,
        },
        {
          name: 'Ngày sinh',
          keyOfMainData: 'ngaySinh',
          type: TypeColumn.DATE,
        },
        {
          name: 'Email',
          keyOfMainData: 'email',
          type: TypeColumn.STRING,
        },
        {
          name: 'Số điện thoại',
          keyOfMainData: 'dienThoaiDiDong',
          type: TypeColumn.STRING,
        },
      ]

      return res
    }
    return actionEvent?.columns
  }, [actionEvent?.columns])

  const onChangeDataTum = (indexColumn: number, name: string, value: any) => {
    const newData = [...(tums ?? [])]
    newData[indexColumn][name] = value
    setTums(newData)
  }

  return (
    <BaseModalComponent
      titleModal={'Cập nhật danh sách'}
      ref={modalRef}
      handleSubmit={handleSubmit}
      widthModal={1250}
      isShowLineBottom={false}
      isLoadingConfirm={isLoading}
      buttonOpenModal={
        <BaseButton
          icon={<EditOutlined rev={undefined} />}
          onClick={() => modalRef?.current?.open?.()}
        >
          {'Cập nhật'}
        </BaseButton>
      }
      renderContent={
        <BaseSpace>
          <BaseRow gutter={16} align={'middle'} justify={'space-between'}>
            <BaseCol>
              <BaseText children={'Danh sách đoàn viên'} />
            </BaseCol>
            <BaseCol>
              <ModalSelectTum
                data={tums}
                onSubmit={(data: IDataTumActivity[]) => {
                  setTums(data)
                }}
              />
            </BaseCol>
          </BaseRow>
          <BaseTableManagement
            columns={[
              ...((columns?.map((column, index) => {
                return {
                  title: column?.name,
                  dataIndex: column?.name,
                  key: column?.keyOfMainData ?? column?.name,
                  fixed: index === 0 ? 'left' : undefined,
                  className: 'big-column',
                  render: (_: any, record: any, indexRecord: number) => {
                    if (!column?.keyOfMainData) {
                      switch (column?.type) {
                        case TypeColumn.STRING: {
                          return (
                            <BaseFormItem hiddenLabel>
                              <BaseInput
                                value={record?.[column?.name ?? '']}
                                placeholder={column?.name}
                                onChange={e =>
                                  onChangeDataTum(
                                    indexRecord,
                                    column?.name ?? '',
                                    e?.target?.value,
                                  )
                                }
                              />
                            </BaseFormItem>
                          )
                        }
                        case TypeColumn.NUMBER: {
                          return (
                            <BaseFormItem hiddenLabel>
                              <BaseInputNumberWrapper
                                value={record?.[column?.name ?? '']}
                                placeholder={column?.name}
                                onChange={value =>
                                  onChangeDataTum(
                                    indexRecord,
                                    column?.name ?? '',
                                    value,
                                  )
                                }
                              />
                            </BaseFormItem>
                          )
                        }
                        case TypeColumn.DATE: {
                          return (
                            <BaseFormItem hiddenLabel>
                              <DatePickerWrapper
                                value={
                                  record?.[column?.name ?? '']
                                    ? dayjs(record?.[column?.name ?? ''])
                                    : undefined
                                }
                                placeholder={column?.name}
                                onChange={value =>
                                  onChangeDataTum(
                                    indexRecord,
                                    column?.name ?? '',
                                    value,
                                  )
                                }
                              />
                            </BaseFormItem>
                          )
                        }

                        case TypeColumn.OTHER: {
                          return (
                            <BaseFormItem hiddenLabel>
                              <BaseInput
                                value={record?.[column?.name ?? '']}
                                placeholder={column?.name}
                              />
                            </BaseFormItem>
                          )
                        }
                      }
                    }

                    return (
                      <BaseText
                        children={record?.[column?.keyOfMainData ?? ''] ?? ''}
                        fontWeight="medium"
                        opacity="0.7"
                      />
                    )
                  },
                }
              }) ?? []) as any),
            ]}
            dataSource={tums}
            className="row-overwrite-style"
            scroll={{ x: true }}
            pagination={{
              pageSize: 5,
            }}
          />
        </BaseSpace>
      }
    />
  )
}

const BaseFormItem = styled(BaseForm.Item)`
  margin-bottom: 0px;
`

const BaseInputNumberWrapper = styled(InputNumber)`
  width: 100%;
`

const DatePickerWrapper = styled(BaseDatePicker)`
  width: 100%;
`
