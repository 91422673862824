import { ITradeUnionMember } from 'app/api/tradeUnionMembers/model'
import R from 'app/assets/R'

export enum GenderTradeUnionEnum {
  All = 0,
  Male = 1,
  Female = 2,
}

export interface IFilterTradeUnionList {
  search?: string
  gender?: GenderTradeUnionEnum
  source?: number
  tinhTrangSinhHoat?: StatusActivitiesEnum
  unitIds?: number[]
}

export const DefineGender = {
  [GenderTradeUnionEnum.All]: {
    textKeyI18n: R.strings.all,
  },
  [GenderTradeUnionEnum.Male]: {
    textKeyI18n: R.strings.male,
  },
  [GenderTradeUnionEnum.Female]: {
    textKeyI18n: R.strings.female,
  },
}

export interface Pagination {
  current: number
  pageSize: number
  total?: number
}

export interface PaginationWithSearch extends Pagination {
  search?: string
}

export interface IDataTable extends ITradeUnionMember {
  key?: number
}


export enum StatusActivitiesEnum {
  THAI_SAN = 'thai_san',
  NGHI_OM = 'nghi_om',
  CHUA_BENH = 'chua_benh',
  NGHI_KHONG_LUONG = 'nghi_khong_luong',
  NGHI_HUU = 'nghi_huu',
  KEO_DAI_CONG_TAC = 'keo_dai_cong_tac',
  DI_HOC_CONG_TAC_TAI_NUOC_NGOAI_DUOI_12_THANG = 'di_hoc_cong_tac_tai_nuoc_ngoai_duoi_12_thang',
  DI_HOC_CONG_TAC_TAI_NUOC_NGOAI_12_THANG_TRO_LEN = 'di_hoc_cong_tac_tai_nuoc_ngoai_12_thang_tro_len',
  DANG_LAM_VIEC = 'dang_lam_viec',
  DA_NGHI_VIEC = 'da_nghi_viec',
}

export const StatusActivitiesMap: { [key in StatusActivitiesEnum]: string } = {
  [StatusActivitiesEnum.THAI_SAN]: 'Thai sản',
  [StatusActivitiesEnum.NGHI_OM]: 'Nghỉ ốm',
  [StatusActivitiesEnum.CHUA_BENH]: 'Chữa bệnh',
  [StatusActivitiesEnum.NGHI_KHONG_LUONG]: 'Nghỉ không lương',
  [StatusActivitiesEnum.NGHI_HUU]: 'Nghỉ hưu',
  [StatusActivitiesEnum.KEO_DAI_CONG_TAC]: 'Kéo dài công tác',
  [StatusActivitiesEnum.DI_HOC_CONG_TAC_TAI_NUOC_NGOAI_DUOI_12_THANG]: 'Đi học/ công tác tại nước ngoài (dưới 12 tháng)',
  [StatusActivitiesEnum.DI_HOC_CONG_TAC_TAI_NUOC_NGOAI_12_THANG_TRO_LEN]: 'Đi học/ công tác tại nước ngoài (12 tháng trở lên)',
  [StatusActivitiesEnum.DANG_LAM_VIEC]: 'Đang làm việc',
  [StatusActivitiesEnum.DA_NGHI_VIEC]: 'Đã nghỉ việc',
};

export const statusActivitiesOptions = [
  {
    label: 'Thai sản',
    value: StatusActivitiesEnum.THAI_SAN,
  },
  {
    label: 'Nghỉ ốm',
    value: StatusActivitiesEnum.NGHI_OM
  },
  {
    label: 'Chữa bệnh',
    value: StatusActivitiesEnum.CHUA_BENH
  },
  {
    label: 'Nghỉ không lương',
    value: StatusActivitiesEnum.NGHI_KHONG_LUONG
  },
  {
    label: 'Nghỉ hưu',
    value: StatusActivitiesEnum.NGHI_HUU
  },
  {
    label: 'Kéo dài công tác',
    value: StatusActivitiesEnum.KEO_DAI_CONG_TAC
  },
  {
    label: 'Đi học/ công tác tại nước ngoài (dưới 12 tháng)',
    value: StatusActivitiesEnum.DI_HOC_CONG_TAC_TAI_NUOC_NGOAI_DUOI_12_THANG
  },
  {
    label: 'Đi học/ công tác tại nước ngoài (12 tháng trở lên)',
    value: StatusActivitiesEnum.DI_HOC_CONG_TAC_TAI_NUOC_NGOAI_12_THANG_TRO_LEN
  },
  {
    label: 'Đang làm việc',
    value: StatusActivitiesEnum.DANG_LAM_VIEC
  },
  {
    label: 'Đã nghỉ việc',
    value: StatusActivitiesEnum.DA_NGHI_VIEC
  },
];


export enum DepartmentsEnum {
  BAN_CHAM_SOC_NGUOI_HOC = "Ban Chăm sóc người học",
  BAN_DAO_TAO = "Ban Đào tạo",
  BAN_NGHIEN_CUU_PHAT_TRIEN_VA_GAN_KET_TOAN_CAU = "Ban Nghiên cứu - Phát triển và Gắn kết toàn cầu",
  BAN_PHAT_TRIEN_TO_CHUC_NHAN_LUC = "Ban Phát triển Tổ chức - Nhân lực",
  BAN_QUAN_TRI_HA_TANG = "Ban Quản trị hạ tầng",
  BAN_TAI_CHINH_KE_HOACH_DAU_TU = "Ban Tài chính - Kế hoạch đầu tư",
  BAN_TRUYEN_THONG_VA_PHAT_TRIEN_DOI_TAC = "Ban Truyến thông và Phát triển đối tác",
  CONG_DOAN_CAC_VIEN_KHOA_THIET_KE_TRUYEN_THONG = "Công đoàn các Viện & Khoa Thiết kế truyền thông",
  CONG_TY_IN_KINH_TE_NHA_XUAT_BAN_KINH_TE = "Công ty In Kinh tế & Nhà xuất bản kinh tế",
  KHOA_CONG_NGHE_THONG_TIN_KINH_DOANH = "Khoa Công nghệ thông tin kinh doanh",
  KHOA_DU_LICH = "Khoa Du lịch",
  KHOA_KE_TOAN = "Khoa Kế toán",
  KHOA_KINH_DOANH_QUOC_TE_MARKETING = "Khoa Kinh doanh quốc tế - Marketing",
  KHOA_KINH_TE = "Khoa Kinh tế",
  KHOA_LUAT = "Khoa Luật",
  KHOA_NGAN_HANG = "Khoa Ngân hàng",
  KHOA_NGOAI_NGU = "Khoa Ngoại ngữ",
  KHOA_QUAN_LY_NHA_NUOC = "Khoa Quản lý nhà nước",
  KHOA_QUAN_TRI = "Khoa Quản trị",
  KHOA_TAI_CHINH = "Khoa Tài chính",
  KHOA_TAI_CHINH_CONG = "Khoa Tài chính công",
  KHOA_TOAN_THONG_KE = "Khoa Toán - Thống kê",
  PHONG_DAM_BAO_CHAT_LUONG_PHAT_TRIEN_CHUONG_TRINH = "Phòng Đảm bảo chất lượng - Phát triển chương trình",
  PHONG_KE_HOACH_DAO_TAO_KHAO_THI = "Phòng Kế hoạch đào tạo - Khảo thí",
  PHONG_THANH_TRA_PHAP_CHE = "Phòng Thanh tra - Pháp chế",
  TAP_CHI_NGHIEN_CUU_KINH_TE_VA_KINH_DOANH_CHAU_A = "Tạp chí Nghiên cứu kinh tế và Kinh doanh Châu Á",
  THU_VIEN = "Thư viện",
  TRAM_Y_TE = "Trạm Y tế",
  TRUNG_TAM_GIAO_DUC_THE_CHAT = "Trung tâm Giáo dục thể chất",
  VAN_PHONG_CELG = "Văn phòng CELG",
  VAN_PHONG_COB = "Văn phòng COB",
  VAN_PHONG_CTD = "Văn phòng CTD",
  VAN_PHONG_DANG_DOAN_THE = "Văn phòng Đảng - Đoàn thể",
  VAN_PHONG_UEH = "Văn phòng UEH",
  VIEN_DAO_TAO_QUOC_TE = "Viện Đào tạo Quốc tế",
  VIEN_KHOA_HOC_CHINH_TRI_XA_HOI = "Viện Khoa học chính trị - xã hội",
  VIEN_NGHIEN_CUU_TU_VAN_PHAT_TRIEN_VUNG = "Viện Nghiên cứu & Tư vấn phát triển vùng",
  VIEN_PHAT_TRIEN_NGUON_NHAN_LUC_VA_KINH_DOANH = "Viện Phát triển Nguồn nhân lực và Kinh doanh",
}

export enum PositionEnum {
  ToTruongCongDoan = "Tổ trưởng Công đoàn",
  ChuTichCongDoanBoPhan = "Chủ tịch Công đoàn bộ phận",
  PhoChuTichCongDoanBoPhan = "Phó Chủ tịch Công đoàn bộ phận",
  UyVienCongDoanBoPhan = "Ủy viên Công đoàn bộ phận",
  ChuTichCongDoanTruongThanhVien = "Chủ tịch Công đoàn Trường thành viên",
  PhoChuTichCongDoanTruongThanhVien = "Phó Chủ tịch Công đoàn Trường thành viên",
  UyVienCongDoanTruongThanhVien = "Ủy viên Công đoàn Trường thành viên",
  ChuTichBanChapHanhCongDoanUEH = "Chủ tịch Ban Chấp hành Công đoàn UEH",
  PhoChuTichBanChapHanhCongDoanUEH = "Phó Chủ tịch Ban Chấp hành Công đoàn UEH",
  UyVienBanChapHanhCongDoanUEH = "Ủy viên Ban Chấp hành Công đoàn UEH",
  UyVienThuongVuUEH = "Ủy viên Thường vụ UEH",
  ChuNhiemUyBanKiemTraCongDoan = "Chủ nhiệm Ủy ban kiểm tra Công đoàn",
  PhoChuNhiemUyBanKiemTraCongDoan = "Phó Chủ nhiệm Ủy ban kiểm tra Công đoàn",
  UyVienUyBanKiemTraCongDoan = "Ủy viên Ủy ban kiểm tra Công đoàn",
  KeToanCongDoanUEH = "Kế toán Công đoàn UEH",
  BanCSPLHoTroChuyenMon = "Ban CSPL - Hỗ trợ chuyên môn",
  BanNuCong = "Ban Nữ công",
  BanToChucTaiChinhTongHop = "Ban Tổ chức - Tài chính - Tổng hợp",
  BanTuyenGiaoVanThe = "Ban Tuyên giáo - Văn thể",
  DoanVien = "Đoàn viên"
}