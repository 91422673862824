import BaseText from 'app/components/common/BaseText'
import { BaseTableManagement } from 'app/components/tables/BaseTableManagement'
import { convertColumnTable } from 'app/components/tables/BaseTableReport/hook'
import { useMemo } from 'react'
import { IActivityEvent, ILocation } from '../../../CreateEvent/type'

export const LocationLayout = ({
  activity,
}: {
  activity?: IActivityEvent
}) => {
  const data = useMemo(() => {
    return activity?.locations?.map((item, index) => {
      return {
        ...item,
        key: index,
      }
    })
  }, [activity?.locations])

  return (
    <BaseTableManagement
      dataSource={data}
      columns={[
        convertColumnTable<ILocation & { key: number }>({
          key: 'key',
          title: 'STT',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'number-column',
          render: (index: number) => {
            return (
              <BaseText
                children={index + 1}
                fontWeight="medium"
                opacity="0.7"
              />
            )
          },
        }),
        convertColumnTable<ILocation>({
          key: 'address',
          title: 'Địa chỉ',
          fixed: 'left',
          classNameWidthColumnOverwrite: 'big-column',
          render: (text?: string) => {
            return <BaseText children={text} fontWeight="medium" />
          },
        }),
        convertColumnTable<ILocation>({
          key: 'key_map_check_in',
          title: 'Toạ độ',
          render: (_, record) => {
            return (
              <BaseText
                children={`${record?.lat}, ${record?.long}`}
                fontWeight="medium"
                opacity="0.7"
              />
            )
          },
        }),

        convertColumnTable<ILocation>({
          key: 'radius',
          title: 'Bán kính (m)',
          render: (text?: string) => {
            return (
              <BaseText children={text} fontWeight="medium" opacity="0.7" />
            )
          },
        }),
      ]}
      scroll={{ x: true }}
      pagination={{
        pageSize: 5,
      }}
      className="row-overwrite-style"
    />
  )
}
