import R from 'app/assets/R'
import { EventStatusEnum } from 'app/containers/EventManagement/type'
import { ParamsList, ResponseType } from 'parkway-web-common'
import { ITradeUnionMember } from '../tradeUnionMembers/model'
import {
  ILocation,
  ITeam,
} from 'app/containers/EventManagement/layouts/CreateEvent/type'

export interface IParamsGetEvents {
  search?: string
  limit?: number
  is_active?: number
  page?: number

  status?: EventStatusEnum[]
  type?: TypeEventEnum[]
}

export interface IParamsGetTradeUnionMemberByEventId extends IParamsGetEvents {
  event_id?: number
}

export enum TypeEventEnum {
  TRAINING = 'training', // Hội thảo, tập huấn
  CARE = 'care', // Chăm lo
  ACTIVITY = 'activity', // Hoạt động
  NEWS = 'news', // Tin tức
}

export enum TypeColumn {
  STRING = 'string',
  DATE = 'date',
  NUMBER = 'number',
  OTHER = 'other',
}

export enum UnionActivitiesCategorizationEnum {
  WomenWorkers = 'women_workers',
  PropagandaCulture = 'propaganda_culture',
  UnionRegulations = 'union_regulations',
  CommendationReward = 'commendation_reward',
  PoliciesLawsProfessionalSupport = 'policies_laws_professional_support',
}

export const DefineUnionActivitiesCategorization = {
  [UnionActivitiesCategorizationEnum.WomenWorkers]: {
    keyI18n: R.strings.women_workers,
  },
  [UnionActivitiesCategorizationEnum.PropagandaCulture]: {
    keyI18n: R.strings.propaganda_culture,
  },
  [UnionActivitiesCategorizationEnum.UnionRegulations]: {
    keyI18n: R.strings.union_regulations,
  },
  [UnionActivitiesCategorizationEnum.CommendationReward]: {
    keyI18n: R.strings.commendation_reward,
  },
  [UnionActivitiesCategorizationEnum.PoliciesLawsProfessionalSupport]: {
    keyI18n: R.strings.policies_laws_professional_support,
  },
}

export interface IEvent {
  id?: number
  name_event?: string
  description?: string
  time_start?: string
  time_end?: string

  time_register_start?: string
  time_register_end?: string

  time_create_event?: string
  unionActivitiesCategorization?: UnionActivitiesCategorizationEnum
  is_deleted?: boolean
  created_at?: string
  updated_at?: string
  addresses?: IAddress[]
  documents?: IDocument[]
  status?: EventStatusEnum

  link?: string

  type?: TypeEventEnum

  activitiesEvent?: IActionEvent[]

  isHaveTeam?: boolean
}

export interface IDocument {
  id?: number
  path?: string
  created_at?: string
}

export interface IAddress {
  id?: number
  address?: string
  created_at?: string
}

export interface IPayloadCreateEvent {
  name_event?: string
  time_start?: string
  time_end?: string
  time_create_event?: string
  documents: string[]
  addresses?: string[]
  description?: string

  time_register_end?: string
  time_register_start?: string
  type?: TypeEventEnum
  link?: string

  isHaveTeam?: boolean
}

export interface IPayloadUpdateEvent {
  id: number
  name_event?: string
  time_end?: string
  time_start?: string
  time_create_event?: string
  documents: string[]
  addresses?: string[]
  description?: string
  time_register_end?: string
  time_register_start?: string
  type?: TypeEventEnum
  link?: string
  isHaveTeam?: boolean
}

export type IResponseEventList = ResponseType<{
  rows: IEvent[]
  count: number
}>

export interface IDeleteEvent {
  id: number
}

export interface ITradeUnionMemberByEvent {
  id?: number
  is_checked_in?: boolean
  event_id?: number
  trade_union_member_id?: number
  event?: IEvent
  tum?: ITradeUnionMember
}

export type IResponseTradeUnionMemberByEventId = ResponseType<{
  rows: ITradeUnionMemberByEvent[]
  count: number
}>

export interface IActionEvent {
  id?: number
  name?: string
  valueCare?: string
  eventId?: number
  action?: string
  createdAt?: string
  updatedAt?: string
  members?: IMemberActionEvent[]
  rewards?: IPrizeActionEvent[]
  columns?: ICategoryActionEvent[]
  event?: IEvent

  total?: number

  teams?: ITeam[]
  locations?: ILocation[]
}

export interface IMemberActionEvent {
  id?: number
  actionEventId?: number
  maso_doanvien?: string
  action?: string
  createdAt?: string
  updatedAt?: string
  values?: IMemberCategoryActionEvent[]
  tum: ITradeUnionMember
  valueCare?: string
  is_checked_in?: boolean

  total?: number
  value_care?: string
}

export interface IMemberCategoryActionEvent {
  id?: number
  idRegisterMember?: number
  idCategory?: number
  column_id?: number
  value?: string
  action?: string
  createdAt?: string
  updatedAt?: string
}

export interface IPrizeActionEvent {
  id?: number
  name?: string
  description?: string
  gift?: string
  countPerReward?: number
  actionEventId?: number
  type_prize?: string
  action?: string
  createdAt?: string
  updatedAt?: string
  type?: string
}

export interface ICategoryActionEvent {
  id?: number
  name_column?: string
  actionEventId?: number
  action?: string
  key_of_main_data?: string
  type_value?: TypeColumn
  createdAt?: string
  updatedAt?: string
}

export interface IPayloadActionEvent {
  activity: {
    name?: string
    event_id?: number
    description?: string
  }
  reward: {
    name: string
    type_prize: string
    description?: string
    gift?: string
    countPerReward?: number
  }[]
  columns?: {
    name?: string
    keyOfMainData?: string
    type?: string
  }[]
  tums?: {
    id: string
    valueCare?: string
    is_checked_in?: boolean
    columns: {
      name: string
      value: string
    }[]
  }[]

  locations?: {
    address: string
    long: string
    lat: string
    radius: number
    key_map_check_in: string
  }[]

  teams?: {
    name?: string
    description?: string
    tums?: {
      id: string
      valueCare?: string
      is_checked_in?: boolean
      list_key_map_check_in?: string[]
      columns: {
        name: string
        value: string
      }[]
    }[]
  }[]
}

export interface IPayloadAddTumByActionEvent {
  activity_id?: number
  tums?: {
    id: string
    valueCare?: string
    is_checked_in?: boolean
    columns: {
      name: string
      value: string
    }[]
  }[]
  teamId?: number
}

export interface IPayloadAddTumToAction {
  activity_id?: number
  tums?: {
    id: string
    valueCare?: string
    columns?: {
      name?: string
      value?: string
    }[]
  }[]
}

export interface IPayloadCreateActionEvent {
  event_id: number
  actions: IPayloadActionEvent[]
}

export enum StatusMemberRegisterWaiting {
  WAITING = 'WAITING',
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface IParamsGetActionRegisteredByMember {
  id?: string
  status?: StatusMemberRegisterWaiting
}

export interface IParamsGetMemberByAction {
  id?: number
  status?: StatusMemberRegisterWaiting
}

export interface IPayloadChangeStatusMemberRegister {
  id?: number
  status: StatusMemberRegisterWaiting
  reason?: string
  team?: number
  locationIds?: number[]
}

export interface IPayloadRegisterActionByMember {
  activity_id?: number
  member_id?: string
}

export interface IPayloadCheckInActionByMember {
  actionId?: number
  tumId: number | string
  value?: boolean

  location?: {
    lat?: string;
    long?: string;
  };
}

export interface IParamsGetActionRegisteredByMember {
  member_id?: string
  status?: StatusMemberRegisterWaiting
}

export interface IParamsGetMemberByAction {
  activity_id?: number
  status?: StatusMemberRegisterWaiting
}

export interface IParamsGetActionByEvent extends ParamsList {
  id: number

  eventId?: number
}

export interface IPayloadPushRewardTum {
  rewardId?: number
  tumActivityIds?: (number | string | undefined)[]
  teamActivityIds?: (number | string | undefined)[];
}
